const accelerometer = {
  id: null,
  target: null,
  callback(event) {
    if (!accelerometer.target) { return }
    const acceleration = event.accelerationIncludingGravity
    accelerometer.target.contentWindow.getAccelerometerData({
      isStarted: true,
      x: acceleration.x * -1,
      y: acceleration.y,
      z: acceleration.z
    })
  },
  start(target) {
    this.target = target
    window.addEventListener('devicemotion', this.callback)
  },
  stop() {
    window.removeEventListener('devicemotion', this.callback)
  }
}

export const sensors = { accelerometer }
