const id = key => `${process.env.REACT_APP_ID}:${key}`

export const storage = {
  setItem(key, value) {
    localStorage.setItem(id(key), JSON.stringify(value))
  },
  removeItem(key) {
    localStorage.removeItem(id(key))
  },
  getItem(key) {
    const data = localStorage.getItem(id(key))
    return data && data !== "undefined" ? JSON.parse(data) : null
  }
}
