/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useRef, useState } from "react"
import { useGetSectionsMutation, useGetSectionGamesMutation } from "../services/apis/commonAPI"
import { CircularProgress } from "@mui/material"
import Slider from "slick-slider-react"
import FeaturedCard from "../components/cards/FeaturedCard"
import SliderCard from "../components/cards/SliderCard"
import ListingCard from "../components/cards/ListingCard"
import "./AppsPage.css"

// games limits for each section type
const limits = {
  "large-slider": 4,
  "medium-slider": 4,
  "small-list": 3
}

export default function AppsPage() {
  // api mutations
  const [getSections] = useGetSectionsMutation()
  const [getGames] = useGetSectionGamesMutation()
  // sections array
  const [sections, setSections] = useState([])
  // current timer
  const [time, setTime] = useState(0)
  // current slide index for each section
  const [slides, setSlides] = useState([])
  // sections loading state
  const [sectionsLoading, setSectionsLoading] = useState(true)
  // loading state for each section
  const loadings = useRef([])
  // current page for each section
  const pages = useRef([])
  // games for each section
  const games = useRef([])
  // method to update slide by index
  const setSlide = (index, data) => {
    const array = [...slides]
    array[index] = data
    setSlides(array)
    const length = games.current[index].length
    if (length === data + 1 && length < sections[index].gameCount) {
      loadGames(sections[index], index)
    }
    setTimeout(() => setTime(Date.now()), 50)
  }
  // method to update loading by index
  const setLoading = (index, data) => {
    loadings.current[index] = data
    setTimeout(() => setTime(Date.now()), 50)
  }
  // method to update page by index
  const setPage = (index, data) => {
    pages.current[index] = data
    setTimeout(() => setTime(Date.now()), 50)
  }
  // method to append games by index
  const addGames = (index, data) => {
    games.current[index] = games.current[index].concat(...data)
    setTimeout(() => setTime(Date.now()), 50)
  }
  // method to load games by section
  const loadGames = (section, index) => {
    // return if section is loading
    if (loadings.current[index]) { return }
    // set loading state
    setLoading(index, true)
    // increase current page
    setPage(index, pages.current[index] + 1)
    // request games by page
    getGames({
      uuid: section.uuid,
      page: pages.current[index],
      limit: limits[section.type]
    }).then(response => {
      // check error state
      if (response.error) {
        // reset loading state
        setLoading(index, false)
      } else {
        // append games
        addGames(index, response.data)
        // reset loading state
        setLoading(index, false)
      }
      setTimeout(() => setTime(Date.now()), 1500)
    })
  }
  // first load on mount
  useEffect(() => {
    setSectionsLoading(true)
    getSections().then(resp => {
      if (!resp.error) {
        setSections(resp.data)
        // current slide index for each section
        setSlides(Array(resp.data.length).fill(0))
        // loading state for each section
        loadings.current = Array(resp.data.length).fill(false)
        // current page for each section
        pages.current = Array(resp.data.length).fill(-1)
        // games for each section
        games.current = Array(resp.data.length).fill([])
        // load games for each section
        setTimeout(() => {
          setSectionsLoading(false)
          resp.data.forEach(loadGames)
        }, 500)
      } else {
        setSections([])
        setSectionsLoading(false)
      }
    })
  }, [])
  // apps page
  return (
    <div className="apps-page" data-time={time}>
      {
        sectionsLoading && (
          <div className="apps-page loading">
            <CircularProgress />
          </div>
        )
      }
      {
        !sectionsLoading && sections.length === 0 && (
          <div className="apps-page empty">
            Games will be available soon.
          </div>
        )
      }
      {
        !sectionsLoading && sections.map((section, index) => {
          const results = games.current[index]
          const loading = loadings.current[index]
          const pending = section.gameCount - results.length
          const limit = limits[section.type]
          const holders = loading
            ? Array(pending > limit ? limit : pending).fill(null)
            : []
          const items = [...results, ...holders]
          if (section.type === "large-slider") {
            return (
              <div
                key={index}
                className="apps-page-section"
                data-section-count={items.length}>
                <div className="apps-page-title large">
                  {section.title}
                </div>
                {
                  items.length > 1 ? (
                    <Slider
                      className="section-slider apps-page-content featured"
                      alignment="center"
                      index={slides[index]}
                      onSlide={slide => setSlide(index, slide)}
                      dragOutbound={50}>
                      {
                        items.map((item, key) => (
                          <FeaturedCard
                            key={key}
                            data={item}
                          />
                        ))
                      }
                    </Slider>
                  ) : (
                    <div className="apps-page-content featured">
                      <FeaturedCard data={items[0]} />
                    </div>
                  )
                }
              </div>
            )
          } else if (section.type === "medium-slider") {
            return (
              <div
                key={index}
                className="apps-page-section"
                data-section-count={items.length}>
                <div className="apps-page-title">
                  {section.title}
                </div>
                {
                  items.length > 1 ? (
                    <Slider
                      className="section-slider apps-page-content slider"
                      alignment="left"
                      index={slides[index]}
                      onSlide={slide => setSlide(index, slide)}
                      dragOutbound={50}>
                      {
                        items.map((item, key) => (
                          <SliderCard
                            key={key}
                            data={item}
                          />
                        ))
                      }
                    </Slider>
                  ) : (
                    <div className="apps-page-content slider">
                      <SliderCard data={items[0]} />
                    </div>
                  )
                }
              </div>
            )
          } else if (section.type === "small-list") {
            return (
              <div
                key={index}
                className="apps-page-section">
                <div className="apps-page-title">
                  {section.title}
                </div>
                <div className="apps-page-content listing">
                  {
                    items.map((item, key) => (
                      <ListingCard
                        key={key}
                        data={item}
                      />
                    ))
                  }
                </div>
                <div className="apps-page-footer">
                  {
                    !loading && results.length < section.gameCount && (
                      <button
                        className="apps-page-footer-button"
                        onClick={() => loadGames(section, index)}>
                        Load More
                      </button>
                    )
                  }
                </div>
              </div>
            )
          } else {
            return null
          }
        })
      }
    </div>
  )
}
