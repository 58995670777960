import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react"
import { env, getHeaders } from "../../utility/helpers"
import { cognito } from "../../modules/cognito"

const commonAPI = createApi({
  reducerPath: "commonAPI",
  baseQuery: fetchBaseQuery({
    baseUrl: env("COMMON_API_BASE_URL"),
    prepareHeaders: getHeaders
  }),
  endpoints: builder => ({
    getThemes: builder.mutation({
      query: () => ({
        url: "/user/v1/config",
        headers: { noAuth: true },
        method: "GET",
        params: {
          env: env("ENVIRONMENT"),
          platform: env("PLATFORM")
        }
      }),
      transformResponse: response => response.data.map(item => ({
        id: item.name,
        ...JSON.parse(item.json)
      }))
    }),
    getData: builder.mutation({
      query: () => ({
        url: `/user/v1/id-token/${cognito.getTokens()?.idToken}`,
        method: "GET"
      }),
      transformResponse: response => response.data
    }),
    getSections: builder.mutation({
      query: () => ({
        url: `/user/v1/games/categories/${env("ENVIRONMENT")}`,
        method: "GET",
        params: {
          platform: env("PLATFORM")
        }
      }),
      transformResponse: response => response.data
    }),
    getSectionGames: builder.mutation({
      query: ({ uuid, page, limit }) => ({
        url: `/user/v1/games/by/category/${uuid}/${limit}/${page}`,
        method: "GET"
      }),
      transformResponse: response => response.data
    }),
    searchGames: builder.mutation({
      query: ({ query }) => ({
        url: `/user/v1/games/by/name/${env("ENVIRONMENT")}/${query}`,
        method: "GET",
        params: {
          platform: env("PLATFORM")
        }
      }),
      transformResponse: response => response.data
    }),
    getGlobalLeaderboard: builder.mutation({
      query: ({ msisdn, limit, page }) => ({
        url: `/game/v1/leaderboard/redis/global/${msisdn}/${limit}/${page}`,
        method: "GET",
        params: {
          platform: env("PLATFORM")
        }
      }),
      transformResponse: response => response.data
    }),
    getAffiliate: builder.mutation({
      query: ({ id }) => ({
        url: `/user/v1/affiliate/${id}`,
        method: "GET",
        params: {
          platform: env("PLATFORM")
        }
      })
    })
  })
})

export const {
  useGetThemesMutation,
  useGetDataMutation,
  useGetSectionsMutation,
  useGetSectionGamesMutation,
  useSearchGamesMutation,
  useGetGlobalLeaderboardMutation,
  useGetAffiliateMutation
} = commonAPI

export default commonAPI
