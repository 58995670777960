import { useState } from "react"
import { useSelector } from "react-redux"
import { useInitiatePaymentMutation } from "../../services/apis/paymentAPI"
import { Dialog } from "@mui/material"
import CloseIcon from "@mui/icons-material/Close"
import { analytics } from "../../modules/analytics"
import { toCoinsUnit } from "../../utility/helpers"
import "./PaymentPopup.css"

export default function PaymentPopup({ open, onClose, game }) {
  // animations state
  const animations = useSelector(states => states.common.animations)
  const [busy, setBusy] = useState(false)
  const [initiatePayment] = useInitiatePaymentMutation()
  const submit = () => {
    // return if busy
    if (busy) { return }
    setBusy(true)
    analytics.logEvent("INITIATE_PAYMENT", { game_name: game?.name })
    // initiate payment to get url
    initiatePayment({ leaderboardUuid: game.leaderboardUuid }).then(response => {
      if (!response.error) {
        // close popup
        onClose()
        // open telegram pay url
        window.open(response.data?.data?.payUrl)
      }
      // reset busy
      setBusy(false)
    })
  }
  return (
    <Dialog
      open={open}
      onClose={onClose}
      transitionDuration={animations === "true" ? undefined : 0}
      classes={{ paper: "payment-popup" }}>
      <button className="payment-close-button" onClick={onClose}>
        <CloseIcon style={{ color: '#444' }} />
      </button>
      <div className="payment-popup-image"
        style={{ backgroundImage: `url(${game?.title_image})` }}
      />
      <div className="payment-popup-title">
        {game?.name}
      </div>
      <div className="payment-popup-description">
        Play now to secure your spot among the
        top {game.winnerCount} and win big
        with coins!
      </div>
      <div className="payment-popup-amount">
        {game?.amount} {toCoinsUnit(game?.amount)}
      </div>
      <button
        className="payment-popup-button"
        disabled={busy}
        onClick={submit}>
        Pay Now
      </button>
    </Dialog>
  )
}
