/* eslint-disable react-hooks/exhaustive-deps */
import { forwardRef, useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { useAddFavoriteMutation, useCheckFavoriteGameMutation, useRemoveFavoriteMutation } from "../../services/apis/favoritesAPI"
import { useCheckSubscriptionMutation } from "../../services/apis/paymentAPI"
import Dialog from "@mui/material/Dialog"
import Slide from "@mui/material/Slide"
import CloseIcon from "@mui/icons-material/Close"
import PaymentPopup from "../popups/PaymentPopup"
import Shine from "../Shine"
import { loadImages, pushRouter, toCoinsUnit } from "../../utility/helpers"
import "./Details.css"

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})

function DetailsCoverItem({ icon, visible }) {
  return visible && <div className={`details-cover-icon ${icon}`} />
}

function DetailsTagItem({ text, visible }) {
  return visible && <div className="details-tag-item">{text}</div>
}

function DetailsInfoItem({ icon, text, visible }) {
  return visible && (
    <div className="details-info-item">
      <div className={`details-info-item-icon ${icon}`} />
      <div className="details-info-item-text">
        {text}
      </div>
    </div>
  )
}

function DetailsButton({ text, variant, onClick, disabled, shine, visible }) {
  return visible !== false && (
    <button
      className={`details-button ${variant} ${shine ? "button-shine" : ""}`}
      disabled={disabled}
      onClick={onClick}>
      {shine && <Shine color="white" />}
      {text}
    </button>
  )
}

export default function Details() {
  // api mutations
  const [checkFavoriteGame] = useCheckFavoriteGameMutation()
  const [addFavoriteGame] = useAddFavoriteMutation()
  const [removeFavoriteGame] = useRemoveFavoriteMutation()
  const [checkSubscription] = useCheckSubscriptionMutation()
  // hash router path
  const router = useSelector(states => states.common.router)
  // current game data
  const data = useSelector(states => states.common.game)
  // animations state
  const animations = useSelector(states => states.common.animations)
  // game favorite states
  const [favorite, setFavorite] = useState(null)
  // images ready state
  const [ready, setReady] = useState(false)
  // apis busy state
  const [busy, setBusy] = useState(false)
  // popup open state
  const isOpen = router.includes("view")
  // payment popup open state
  const isPaymentOpen = router.includes("payment")
  // preload images on open
  useEffect(() => {
    if (isOpen && data) {
      setFavorite(null)
      checkFavoriteGame({ gameId: data.id }).then(resp => {
        setFavorite(resp.data ? true : false)
        loadImages(data.bg_image, data.title_image).then(() => {
          if (isOpen) { setReady(true) }
        })
      })
    }
  }, [isOpen, data])
  // reset ready state on open
  useEffect(() => { if (!isOpen) { setReady(false) } }, [isOpen])
  // method to toggle favorite state
  const toggleFavoriteState = () => {
    // set busy state
    setBusy(true)
    // switch by favorite state
    if (favorite) {
      // toggle favorite
      setFavorite(false)
      // remove from favorites
      removeFavoriteGame({ gameId: data.id }).then(() => {
        // reset busy state
        setBusy(false)
      })
    } else {
      // toggle favorite
      setFavorite(true)
      // add to favorites
      addFavoriteGame({ gameId: data.id }).then(() => {
        // reset busy state
        setBusy(false)
      })
    }
  }
  // method to share auto play url
  const shareGame = () => {
    navigator.share({
      url: window.location.origin + window.location.pathname + "?play=" + data.id
    })
  }
  // method to play game
  const playGame = () => {
    // check game mode
    if (!data.paidGame) {
      // open free game
      pushRouter("gameplay")
    } else {
      // set busy state
      setBusy(true)
      // check payment status
      checkSubscription({ leaderboardUuid: data.leaderboardUuid }).then(status => {
        // check response
        if (!status.error) {
          if (status.data === true) {
            // open gameplay
            pushRouter("gameplay")
          } else if (status.data === false) {
            // show payment popup
            pushRouter("payment")
          }
        }
        // reset busy state
        setBusy(false)
      })
    }
  }
  // return if no current data
  if (!data) { return }
  // details component
  return (
    <Dialog
      open={isOpen}
      onClose={() => window.history.back()}
      fullWidth={true}
      classes={{ paper: "details" }}
      transitionDuration={animations === "true" ? undefined : 0}
      TransitionComponent={Transition}>
      <button
        className="details-close-button"
        onClick={() => window.history.back()}
        disabled={busy}>
        <CloseIcon />
      </button>
      <div className="details-inner">
        <div className="details-cover" data-ready={ready}>
          <div className="details-cover-inner"
            style={{
              backgroundImage: `url(${data.title_image}), url(${data.bg_image})`
            }}>
            <Shine
              color={data.paidGame ? "gold" : "white"}
              visible={ready && data.winnerCount > 0}
            />
            <div className="details-cover-icons">
              <DetailsCoverItem icon="paid" visible={data.paidGame} />
              <DetailsCoverItem icon="rewards" visible={data.winnerCount > 0} />
              <DetailsCoverItem icon="global" visible={data.globalLb} />
            </div>
          </div>
        </div>
        <div className="details-header">
          <div className="details-title">
            {data.name}
          </div>
          {
            favorite !== null && (
              <button
                className="details-favorite-button"
                data-active={favorite}
                disabled={busy}
                onClick={toggleFavoriteState}
              />
            )
          }
        </div>
        <div className="details-tag-list">
          <DetailsTagItem text="Free" visible={!data.paidGame} />
          <DetailsTagItem text="Rewards" visible={data.winnerCount > 0} />
          <DetailsTagItem text="Global" visible={data.globalLb} />
        </div>
        <div className="details-info-list">
          <DetailsInfoItem
            icon="winners"
            text={`${data.winnerCount} Winners`}
            visible={data.winnerCount > 0}
          />
          <DetailsInfoItem
            icon="global"
            text="Global Leaderboard"
            visible={data.globalLb}
          />
          <DetailsInfoItem
            icon="price"
            text={`${data.amount} ${toCoinsUnit(data.amount)}`}
            visible={data.paidGame}
          />
          <DetailsInfoItem
            icon="price"
            text="Free to Play"
            visible={!data.paidGame}
          />
        </div>
        <DetailsButton
          variant="outlined"
          disabled={busy}
          onClick={shareGame}
          visible={navigator.share}
          text="Share Link"
        />
        <DetailsButton
          variant="filled"
          disabled={busy}
          onClick={playGame}
          shine={data.paidGame}
          text="Play Game"
        />
      </div>
      <PaymentPopup
        open={isPaymentOpen}
        onClose={() => window.history.back()}
        game={data}
      />
    </Dialog>
  )
}
