import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react"
import { env, getHeaders } from "../../utility/helpers"

const paymentAPI = createApi({
  reducerPath: "paymentAPI",
  baseQuery: fetchBaseQuery({
    baseUrl: env("COMMON_API_BASE_URL"),
    prepareHeaders: getHeaders
  }),
  endpoints: builder => ({
    checkSubscription: builder.mutation({
      query: ({ leaderboardUuid }) => ({
        url: `/reward/test/otp/user/${leaderboardUuid}`,
        method: "GET",
        params: {
          platform: env("PLATFORM")
        }
      }),
      transformResponse: (response) => {
        if (typeof response === 'boolean') { return response }
        return null
      },
    }),
    initiatePayment: builder.mutation({
      query: ({ leaderboardUuid }) => ({
        url: "/reward/test/otp/initiate",
        method: "POST",
        body: {
          type: "",
          leaderboard_uuid: leaderboardUuid,
          additional_info: ""
        },
        params: {
          platform: env("PLATFORM")
        }
      }),
      transformResponse: (response) => response,
    })
  })
})

export const {
  useCheckSubscriptionMutation,
  useInitiatePaymentMutation
} = paymentAPI

export default paymentAPI
