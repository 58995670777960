import "./Footer.css"

const pages = ["apps", "favorites", "chart", "settings"]

export default function Footer({ page, onChange }) {
  return (
    <div className="footer">
      {
        pages.map(item => (
          <button
            key={item}
            className={`footer-button ${item} _img_menu_component_icon_${item}`}
            data-active={item === page}
            onClick={() => onChange(item)}
          />
        ))
      }
    </div>
  )
}
