/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react"
import { useDispatch } from "react-redux"
import { setGame } from "../../services/store"
import Shine from "../../components/Shine"
import Flags from "../../components/Flags"
import { getGameDetails, loadImages, pushRouter } from "../../utility/helpers"
import "./ListingCard.css"

export default function ListingCard({ data }) {
  const dispatch = useDispatch()
  const [ready, setReady] = useState(false)
  useEffect(() => {
    if (!data) { return }
    loadImages(data.thumbnail).then(() => {
      setReady(true)
    })
  }, [data])
  return (
    <div className="listing-card" data-ready={data ? true : false}>
      <div
        className="listing-card-image"
        data-ready={ready}>
        {
          data && (
            <div
              className="listing-card-image-inner"
              style={{
                backgroundImage: `url(${data.thumbnail})`
              }}>
              <Flags data={data} />
              <Shine
                visible={ready && data.winnerCount > 0}
                color={data.paidGame ? "gold" : "white"}
              />
            </div>
          )
        }
      </div>
      <div className="listing-card-content">
        <div className="listing-card-title">
          {data?.name}
        </div>
        <div className="listing-card-details">
          {getGameDetails(data)}
        </div>
      </div>
      <div className="listing-card-buttons">
        {
          data && (
            <button
              className="listing-card-button"
              onClick={() => {
                dispatch(setGame(data))
                pushRouter("view")
              }}>
              Play
            </button>
          )
        }
      </div>
    </div>
  )
}
