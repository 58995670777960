import { storage } from "./storage"

// aws constants
const AWS_DOMAIN = process.env.REACT_APP_AWS_DOMAIN
const AWS_REGION = process.env.REACT_APP_AWS_REGION

// cognito constants
const BASE_URL = `https://${AWS_DOMAIN}.auth.${AWS_REGION}.amazoncognito.com`
const CLIENT_ID = process.env.REACT_APP_COGNITO_CLIENT_ID
const SCOPE = process.env.REACT_APP_COGNITO_SCOPE
const REDIRECT_URI = window.location.origin + window.location.pathname

// helper to get token payloads
const getTokenPayload = token => {
  if (!token) { return null }
  try {
    // decode token payload
    return JSON.parse(window.atob(token.split(".")[1]))
  } catch (err) {
    // parse error
    return null
  }
}

// helper to check token expiry time
const isExpired = token => {
  if (!token) { return true }
  try {
    // decode token payload
    const payload = getTokenPayload(token)
    // check with current time
    return (payload.exp - 60) * 1000 < Date.now()
  } catch (err) {
    // calculation error
    return true
  }
}

// helper to generate verifier and challenge codes
const generateCodes = async () => {
  // method to encode base 64
  const encode = arr => {
    return window.btoa(String.fromCharCode.apply(null, arr))
      .replace(/\+/g, '-')
      .replace(/\//g, '_')
      .replace(/=+$/, '')
  }
  // generate verifier
  const array = new Uint8Array(32)
  window.crypto.getRandomValues(array)
  const verifier = encode(array)
  // generate challenge
  const encoder = new TextEncoder()
  const data = encoder.encode(verifier)
  const hashBuffer = await crypto.subtle.digest('SHA-256', data)
  const hashArray = Array.from(new Uint8Array(hashBuffer))
  const challenge = encode(hashArray)
  // return codes
  return { code_challenge: challenge, code_verifier: verifier }
}

// helper to redirect to a given path
const redirect = (path = window.location.pathname) => {
  window.location.href = path
}

// get code params from url
const code = new URLSearchParams(window.location.search).get("code")

/** Amazon Cognito Module */
export const cognito = {
  /**
   * Login through Amazon Cognito
   * @param {'login' | 'logout'} type - Authentication type
   */
  async authenticate(type) {
    // switch by type
    if (type === "login") {
      // generate challenge and verifier
      const codes = await generateCodes()
      // create hosted ui params
      const params = new URLSearchParams({
        response_type: "code",
        scope: SCOPE,
        client_id: CLIENT_ID,
        redirect_uri: REDIRECT_URI,
        code_challenge: codes.code_challenge,
        code_challenge_method: "S256"
      }).toString()
      // store verifier locally
      storage.setItem("code_verifier", codes.code_verifier)
      // redirect to hosted login ui with params
      redirect(`${BASE_URL}/oauth2/authorize?${params}`)
    } else if (type === "logout") {
      // clear local tokens
      storage.removeItem("id_token")
      storage.removeItem("access_token")
      storage.removeItem("refresh_token")
      // create hosted ui params
      const params = new URLSearchParams({
        client_id: CLIENT_ID,
        logout_uri: REDIRECT_URI,
      }).toString()
      // redirect to logout endpoint with params
      redirect(`${BASE_URL}/logout?${params}`)
    }
  },
  /** Cognito status */
  getStatus() {
    // pending authentication
    if (code) { return "pending" }
    // get tokens
    const tokens = this.getTokens()
    // get expiry status
    const idExpired = isExpired(tokens.idToken)
    const accessExpired = isExpired(tokens.accessToken)
    const refreshExpired = !tokens.refreshToken
    // token validity flags
    const accessValidity = !idExpired && !accessExpired
    const refreshValidity = tokens.idToken && tokens.refreshToken && !refreshExpired
    // return logged status
    return accessValidity || refreshValidity ? "logged" : "login"
  },
  /** Get Cognito tokens */
  getTokens() {
    return ({
      idToken: storage.getItem("id_token"),
      accessToken: storage.getItem("access_token"),
      refreshToken: storage.getItem("refresh_token")
    })
  },
  /** Get token payloads */
  getTokenPayloads() {
    return ({
      idToken: getTokenPayload(storage.getItem("id_token")),
      accessToken: getTokenPayload(storage.getItem("access_token"))
    })
  },
  //** Get access token */
  getAccessToken() {
    return new Promise(resolve => {
      // get url params
      const params = new URLSearchParams(window.location.search)
      // resolve token if exists in params
      if (params.get("token")) { resolve(params.get("token")) }
      // get tokens
      const tokens = this.getTokens()
      // check token status
      if (!isExpired(tokens.accessToken)) {
        // resolve access token
        resolve(tokens.accessToken)
      } else if (tokens.refreshToken) {
        // request with refresh token
        fetch(`${BASE_URL}/oauth2/token`, {
          method: "POST",
          headers: {
            "Content-Type": "application/x-www-form-urlencoded"
          },
          body: new URLSearchParams({
            grant_type: "refresh_token",
            client_id: CLIENT_ID,
            refresh_token: tokens.refreshToken
          })
        }).then(resp => {
          resp.json().then(data => {
            // update tokens
            storage.setItem("id_token", data.id_token)
            storage.setItem("access_token", data.access_token)
            // resolve access token
            resolve(data.access_token)
          }).catch(() => this.authenticate("logout"))
        }).catch(() => this.authenticate("logout"))
      }
    })
  }
}

// check for code param
if (code) {
  // request tokens
  fetch(`${BASE_URL}/oauth2/token`, {
    method: "POST",
    headers: {
      "Content-Type": "application/x-www-form-urlencoded"
    },
    body: new URLSearchParams({
      grant_type: "authorization_code",
      client_id: CLIENT_ID,
      code: code,
      redirect_uri: REDIRECT_URI,
      code_verifier: storage.getItem("code_verifier"),
    })
  }).then(resp => {
    resp.json().then(data => {
      // check tokens on response
      if (data.id_token && data.access_token && data.refresh_token) {
        // store tokens locally
        storage.setItem("id_token", data.id_token)
        storage.setItem("access_token", data.access_token)
        storage.setItem("refresh_token", data.refresh_token)
        // remove verifier
        storage.removeItem("code_verifier")
      }
      // redirect to clear tokens from url
      redirect()
    }).catch(() => redirect())
  }).catch(() => redirect())
}
