import { useDispatch, useSelector } from "react-redux"
import { setAnimations, setTheme } from "../services/store"
import "./SettingsPage.css"

export default function SettingsPage() {
  const dispatch = useDispatch()
  // current theme and animations state
  const theme = useSelector(states => states.common.theme)
  const switchable = useSelector(states => states.common.switchable)
  const animations = useSelector(states => states.common.animations)
  return (
    <div className="settings-page">
      <div className="settings-page-title">
        Settings
      </div>
      <div className="settings-page-content">
        {
          switchable && (
            <div className="settings-page-item">
              <div className="settings-page-item-label">
                <div className="settings-page-item-label-title">
                  Dark Mode
                </div>
                <div className="settings-page-item-label-description">
                  Switch between light and dark themes
                </div>
              </div>
              <button
                className="settings-page-item-switch"
                data-enabled={theme === "dark"}
                onClick={() => {
                  dispatch(setTheme(theme === "dark" ? "light" : "dark"))
                }}
              />
            </div>
          )
        }
        <div className="settings-page-item">
          <div className="settings-page-item-label">
            <div className="settings-page-item-label-title">
              UI Animations
            </div>
            <div className="settings-page-item-label-description">
              Turn this off for better performance
            </div>
          </div>
          <button
            className="settings-page-item-switch"
            data-enabled={animations === "true"}
            onClick={() => {
              dispatch(setAnimations(animations === "true" ? "false" : "true"))
            }}
          />
        </div>
      </div>
    </div>
  )
}
