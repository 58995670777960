import { useSelector } from "react-redux"
import Avatar from "../user/Avatar"
import ProfilePopup from "../popups/ProfilePopup"
import "./Header.css"

export default function Header() {
  // hash router data
  const router = useSelector(states => states.common.router)
  // user data
  const user = useSelector(states => states.common.user)
  // profile popup open state
  const isPopupOpen = router.includes("profile")
  // header component
  return (
    <div className="header">
      <div className="header-logo _img_logo" />
      <Avatar
        image={user?.image}
        onClick={() => window.location.href = "#profile"}
      />
      <ProfilePopup
        open={isPopupOpen}
        onClose={() => window.history.back()}
        data={user}
      />
    </div>
  )
}
