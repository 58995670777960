import { cognito } from "../modules/cognito"

export const env = name => {
  return process.env[`REACT_APP_${name}`]
}

export async function getHeaders(headers) {
  // get access token
  const token = await cognito.getAccessToken()
  // logout if token not available
  if (!token) { cognito.authenticate("logout") }
  // check no auth flag
  if (headers.get("noAuth")) {
    // delete no auth flag
    headers.delete("noAuth")
  } else {
    // set authorization header
    headers.set("Authorization", `Bearer ${token}`)
  }
  // return updated headers
  return headers
}

export function pushRouter(path) {
  const hash = window.location.hash.replace("#", "")
  window.location.href = hash.length > 0 ? `#${hash}:${path}` : `#${path}`
}

export function loadImages(...paths) {
  return new Promise(resolve => {
    const items = []
    const callback = data => {
      items.push(data)
      if (items.length === paths.length) { resolve(items) }
    }
    for (let i = 0; i < paths.length; i++) {
      const image = new Image()
      image.addEventListener("load", () => callback(image))
      image.addEventListener("error", () => callback(null))
      image.src = paths[i]
    }
  })
}

export function toCoinsUnit(amount) {
  return amount === 1 ? "Coin" : "Coins"
}

export function getGameDetails(data) {
  return data ? [
    data.winnerCount > 0 ? `${data.winnerCount} Winners • ` : "",
    data.paidGame ? `${data.amount} ${toCoinsUnit(data.amount)}` : "FREE"
  ].join(" ") : ""
}

function createColorPallet(data) {
  return data.map(item => `${item.name}: ${item.color} !important;`).join("")
}

function loadImage(url) {
  return new Promise(resolve => {
    const image = new Image()
    image.addEventListener("load", resolve)
    image.addEventListener("error", resolve)
    image.addEventListener("cancel", resolve)
    image.addEventListener("abort", resolve)
    image.src = url
  })
}

export async function loadTheme(themes) {
  // return if no themes
  if (themes.length === 0) { return false }
  // find prioritized theme
  const data = [...themes].sort((a, b) => (
    a.priority > b.priority ? -1 : 1
  ))[0]
  // set page title
  document.title = data.general.title
  // update favicon
  const favicon = document.querySelector(`link[rel="icon"]`)
  favicon.setAttribute("href", data.images[0].src)
  // create style element
  const style = document.createElement("style")
  // check light theme availability
  if (data.colors.no_light) {
    style.innerHTML += `
      :root[data-theme="dark"] {${createColorPallet(data.colors.dark)}}
      :root[data-theme="light"] {${createColorPallet(data.colors.dark)}}
    `
  } else {
    style.innerHTML += `
      :root[data-theme="dark"] {${createColorPallet(data.colors.dark)}}
      :root[data-theme="light"] {${createColorPallet(data.colors.light)}}
    `
  }
  // for each image
  for (let i = 0; i < data.images.length; i++) {
    // current image
    const image = data.images[i]
    // continue if no image source
    if (!image.src) { continue }
    // preload image
    await loadImage(image.src)
    // add style rule
    style.innerHTML += ` ._img_${image.name} {
      background-image: url(${image.src}) !important;
    } `
  }
  // append style element
  document.head.appendChild(style)
  // return theme switchable flag
  return new Promise(resolve => {
    setTimeout(() => {
      resolve(!data.colors.no_light)
    }, 500)
  })
}
