/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useRef, useState } from "react"
import { useSelector } from "react-redux"
import { useSearchGamesMutation } from "../../services/apis/commonAPI"
import { analytics } from "../../modules/analytics"
import ListingCard from "../cards/ListingCard"
import "./Search.css"

export default function Search() {
  // hash router path
  const router = useSelector(states => states.common.router)
  // api mutations
  const [search] = useSearchGamesMutation()
  // search query input
  const inputQuery = useRef("")
  const loadedQuery = useRef("")
  const inputElement = useRef(null)
  // time state
  const [time, setTime] = useState(0)
  // search results
  const [results, setResults] = useState([])
  // loading states
  const [loading, setLoading] = useState(false)
  // search open state
  const isSearchOpen = router.includes("search")
  // search view state
  const isSearchView = router[0] === "search" && router.length === 1
  // method to get results
  const getResults = query => {
    inputQuery.current = query
    setTime(Date.now())
    if (loading) { return } else { setLoading(true) }
    if (query === "") {
      loadedQuery.current = query
      setLoading(false)
      setResults([])
    } else {
      search({ query }).then(resp => {
        setResults(!resp.error ? resp.data : [])
        loadedQuery.current = query
        setLoading(false)
      })
    }
  }
  // method to close search
  const onClose = () => {
    window.history.back()
    inputQuery.current = ""
    loadedQuery.current = ""
    setTime(Date.now())
  }
  // hide overflow content on search
  useEffect(() => {
    document.body.setAttribute("data-overflow", !isSearchView)
  }, [isSearchView])
  // clear search query on back to home
  useEffect(() => {
    if (!isSearchOpen) {
      inputQuery.current = ""
      loadedQuery.current = ""
      setTime(Date.now())
    } else {
      inputElement.current?.focus()
      analytics.logEvent("OPEN_SEARCH")
    }
  }, [isSearchOpen])
  useEffect(() => {
    if (!loading && loadedQuery.current !== inputQuery.current) {
      setTimeout(() => {
        getResults(inputQuery.current)
      }, 500)
    }
  }, [loading])
  // search component
  return (
    <div className="search" data-time={time}>
      <div className="search-header">
        <input
          className="search-input"
          placeholder="Search Games"
          onClick={() => window.location.href = "#search"}
          onFocus={() => window.location.href = "#search"}
          onKeyDown={() => window.location.href = "#search"}
          spellCheck={false}
          value={inputQuery.current}
          onChange={event => getResults(event.target.value)}
          ref={inputElement}
        />
        {
          isSearchOpen && (
            <button
              className="search-close-button"
              onClick={onClose}
            />
          )
        }
      </div>
      {
        isSearchOpen && (
          <div
            className="search-result"
            onClick={inputQuery.current === "" ? onClose : null}>
            {
              loadedQuery.current === inputQuery.current && (
                results.length > 0 && inputQuery.current !== ""
                  ? results.map((item, index) => (
                    <ListingCard
                      key={index} data={item}
                    />
                  ))
                  : !loading && inputQuery.current !== "" && (
                    <div className="search-result-empty">
                      Sorry, no games found.
                    </div>
                  )
              )
            }
            {
              loadedQuery.current !== inputQuery.current && (
                <div className="search-result-empty">
                  Loading...
                </div>
              )
            }
          </div>
        )
      }
    </div>
  )
}
