import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react"
import { env, getHeaders } from "../../utility/helpers"

const chartAPI = createApi({
  reducerPath: "chartAPI",
  baseQuery: fetchBaseQuery({
    baseUrl: env("MATRIX_API_BASE_URL"),
    prepareHeaders: getHeaders
  }),
  endpoints: builder => ({
    getSkillMatrix: builder.mutation({
      query: () => ({
        url: "/v1/skillmatrix",
        method: "GET",
        params: {
          platform: env("PLATFORM")
        }
      }),
      transformResponse: response => ({
        labels: response.skillMatrix.map(item => (
          item.name.split(" ").map(word => (
            word[0].toUpperCase() + word.substring(1)
          )).join(" ")
        )),
        zScores: response.skillMatrix.map(item => item.zScore),
        lowerScorePercents: response.skillMatrix.map(item => (
          item.lowerScorePercent
        ))
      })
    })
  })
})

export const {
  useGetSkillMatrixMutation
} = chartAPI

export default chartAPI
